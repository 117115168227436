
import { defineComponent, reactive, ref, toRefs, computed, nextTick, onUnmounted, createVNode } from 'vue';
import useDealerOverview from '@/hooks/rv/useDealerOverview';
import InvoiceLicenseSub from '@/views/RetailValidation/Dealer/InvoiceLicenseSub/index.vue';
import BatchUploadSub from '@/views/RetailValidation/Dealer/InvoiceLicenseSub/BatchUploadSub.vue';
import FailReason from '@/views/RetailValidation/Dealer/FailReason/index.vue';
import RetailValidationDetail from '@/views/RetailValidation/Dealer/RetailValidationDetail/index.vue';
import {
  DealerTableData,
  EnumDealerApprovalStatus,
  EnumDealerCheckStatus
} from '@/views/RetailValidation/Dealer/types';
import NoticeModal from '@/components/NoticeModal.vue';
import withdrawInfo from '@/views/RetailValidation/Dealer/WithdrawInfo.vue';
import EditInfo from '@/views/RetailValidation/Dealer/EditInfo.vue';
import {
  cancelUploadFile,
  getvinMdfCheckFlag,
  getfileSize,
  getBasicDetail,
  postCallback,
  getSpecialUsageItem,
  rvCallBackNormal,
  relationProofPreCheck
} from '@/API/rv/rvDealer';
import { getDelayCallBackRuleHistory } from '@/API/checking/delayCallBackRuleSetup';
import cleanEmptyEleArray from '@/utils/rv/cleanEmptyElement';
import BrandSelector from '@/views/RetailValidation/component/BrandSelector.vue';
import { message, Modal } from 'ant-design-vue';
import { useStore } from 'vuex';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { RvApprovalStatus } from '@/views/RetailValidation/Dealer/types';
import moment from 'moment';

const usageArray = ['最终用户用车', '经销商用车', '其他', '全部'];
const specialUsageArray = ref<any>([]);
const entityArray = ['FBAC', 'BBAC', 'MBCL', '全部'];
const brandArray = ['MB', 'AMG', 'EQ', 'VAN', 'DENZA', 'Maybach', '全部'];
const tableHeight = window.innerHeight - 456;
const tableWidth = window.innerWidth;

export default defineComponent({
  components: {
    InvoiceLicenseSub,
    BatchUploadSub,
    RetailValidationDetail,
    NoticeModal,
    withdrawInfo,
    FailReason,
    EditInfo,
    BrandSelector
  },
  setup() {
    const dealerCode = useStore().state.user.organization.entityCode;
    // 详情弹窗是否显示
    const isDetailVisible = ref<boolean>(false);
    const entityData = ref<string>('');
    const {
      queryParams,
      overview,
      getOverview,
      getTableData,
      isOpenPanle,
      isOpen,
      tableData,
      total,
      pictureModal,
      activeKey,
      resetEvent,
      mulSearchEvent,
      downloadImg,
      showFailFlag,
      overviewSerachEvent,
      pageChange,
      licenseEvent,
      invoiceEvent,
      relationProofEvent,
      sizeChange,
      failData,
      viewReasonEvent,
      handleClick,
      handleActiveKey,
      showCollapsed,
      columns,
      batchQuery,
      approvalStatusArray,
      rebateFlagArray,
      dateChangeEvent,
      selectData,
      state,
      handleExportResult
    } = useDealerOverview();
    // 表格复选框逻辑
    // const state = reactive<any>({
    //     selectedRowKeys: [], // Check here to configure the default column
    //     selectedRows: [],
    // });
    const hasSelected = computed(() => state.selectedRowKeys.length > 0);
    // 发票提交和行驶证提交显示
    const batchRef = ref();
    const isSubVisible = ref<boolean>(false);
    const isInvoice = ref<boolean>(false);
    const isLicense = ref<boolean>(false);
    const isRelationProof = ref<boolean>(false);
    const btnCon = ref<string>('');
    const selectInfo = reactive([]);
    // 批量上传
    const isBatchVisible = ref<boolean>(false);
    const batchActiveKey = ref<string>('1');
    // 弹窗初始化
    const modalVisible = ref<boolean>(false);
    const maskClosable = ref(false);
    const duration = ref(0);
    const withdrawModelVisible = ref<boolean>(false);
    const withdrawCompletedVisible = ref<boolean>(false);
    const modalData = reactive<Record<string, unknown>>({});
    const isProofVisible = ref(false);
    const isProofContent = ref('');
    const isNum = ref(0);

    const params = reactive({
      noticeType: '',
      content: '',
      btnVisible: false,
      withdrawBtnVisible: false,
      vinNo: '',
      recordId: -1,
      retailDate: '',
      brand: '',
      cesarSyncStatus: 0
    });
    const textContentNo = ref<string>('');
    const textNoArray = ref<string[]>([]);
    const input = ref();
    const batchPlaceholder = ref(['', '', '', '', '', '']);
    // 编辑信息初始化
    const editInfoVisible = ref<boolean>(false);
    const editInfoData = reactive({});
    // 取消单选按钮
    const handleClickRow = () => {
      state.selectedRowKeys = [];
      state.selectedRows = [];
    };
    const visibleWithdrawInfoModal = ref(false);
    const withdrawInfoModalVinNo = ref('');
    let withdrawInfoModalRvApplyId: number | undefined = undefined;
    const withdrawInfoColumns = [
      {
        title: '项目编号',
        dataIndex: 'programCode',
        key: 'programCode',
        width: 150
        //ellipsis: true
      },
      {
        title: '项目名称',
        dataIndex: 'programNameCn',
        key: 'programNameCn',
        width: 150
      },
      {
        title: '项目类型',
        dataIndex: 'offerTypeNameCn',
        key: 'offerTypeNameCn',
        width: 150
      },
      {
        title: '申请状态',
        dataIndex: 'status',
        key: 'status',
        width: 150,
        slots: { customRender: 'applyStatus' }
      }
    ];
    const withdrawInfoData = ref<any>([]);
    const disabledWithdraw = computed(() => {
      return withdrawInfoData.value?.some(
        (item: any) => !['已提交', '已终止', '延期撤回', '已撤回'].includes(item.status)
      );
    });
    const onSelectChange = (selectedRowKeys: [], selectedRows: []) => {
      state.selectedRowKeys = selectedRowKeys;
      state.selectedRows = selectedRows;
      nextTick(() => {
        document.getElementsByClassName('ant-radio-checked')[0].addEventListener('click', handleClickRow);
      });
    };
    const isPath = async (path: string, type: string) => {
      if (path) {
        const param = {
          fileKey: path
        };
        await getfileSize(param).then((res: any) => {
          const params = {
            type: type,
            size: res
          };
          sessionStorage.setItem('typePath', JSON.stringify(params));
        });
      }
    };
    // 发票行驶证弹窗验证
    const invoiceLicenseSub = async (butType: string) => {
      btnCon.value = butType;
      if (state.selectedRows && state.selectedRows.length > 0) {
        if (state.selectedRows[0].approlStatus === '') {
          maskClosable.value = false;
          duration.value = 0;
          modalVisible.value = true;
          if (state.selectedRows[0].usage === '最终用户用车') {
            if (butType === 'invoice') {
              params.noticeType = 'warning';
              params.content = '请选择有效信息!';
              params.btnVisible = false;
            } else {
              params.noticeType = 'warning';
              params.content = '该车无需提交行驶证';
              params.btnVisible = false;
            }
          } else {
            params.noticeType = 'warning';
            params.content = '请选择有效信息!';
            params.btnVisible = false;
          }
          Object.assign(modalData, params);
        } else {
          if (state.selectedRows[0].checkStatus == '4444') {
            if (butType === 'invoice') {
              params.noticeType = 'error';
              params.content = `系统查询到该车辆已发生退车，不可提交发票。`;
              params.btnVisible = false;
            } else {
              if (state.selectedRows[0].usage === '最终用户用车') {
                params.noticeType = 'warning';
                params.content = '该车无需提交行驶证';
                params.btnVisible = false;
              } else {
                params.noticeType = 'error';
                params.content = `系统查询到该车辆已发生退车，不可提交行驶证。`;
                params.btnVisible = false;
              }
            }
            modalVisible.value = true;
            Object.assign(modalData, params);
          } else if (state.selectedRows[0].overdueFlag == 1) {
            maskClosable.value = false;
            duration.value = 0;
            modalVisible.value = true;
            if (state.selectedRows[0].usage === '最终用户用车') {
              if (butType === 'invoice') {
                params.noticeType = 'warning';
                params.content = '已过期无法提交';
                params.btnVisible = false;
              } else {
                params.noticeType = 'warning';
                params.content = '该车无需提交行驶证';
                params.btnVisible = false;
              }
            } else {
              params.noticeType = 'warning';
              params.content = '已过期无法提交';
              params.btnVisible = false;
            }
            Object.assign(modalData, params);
          } else {
            if (state.selectedRows[0].usage === '最终用户用车') {
              if (butType === 'invoice') {
                if (state.selectedRows[0].approlStatus === '0000') {
                  isSubVisible.value = true;
                  isInvoice.value = true;
                  isLicense.value = false;
                  isRelationProof.value = false;
                  Object.assign(selectInfo, state.selectedRows[0]);
                } else if (state.selectedRows[0].approlStatus === '0001') {
                  maskClosable.value = false;
                  duration.value = 0;
                  modalVisible.value = true;
                  params.noticeType = 'warning';
                  params.content = '已过期无法提交，请通过延期申报模块提交零售文件';
                  params.btnVisible = false;
                  Object.assign(modalData, params);
                } else if (state.selectedRows[0].approlStatus === '1111') {
                  maskClosable.value = false;
                  duration.value = 0;
                  modalVisible.value = true;
                  params.noticeType = 'warning';
                  if (state.selectedRows[0].checkStatus === '4444') {
                    params.content = '已过期且退车，无法提交';
                  } else {
                    params.content = '已过期无法提交，请通过延期申报模块提交零售文件';
                  }
                  params.btnVisible = false;
                  Object.assign(modalData, params);
                } else if (state.selectedRows[0].approlStatus === '2222') {
                  maskClosable.value = false;
                  duration.value = 0;
                  modalVisible.value = true;
                  params.noticeType = 'warning';
                  params.content = '该台车重复申报，无法提交';
                  params.btnVisible = false;
                  Object.assign(modalData, params);
                }
              } else {
                maskClosable.value = false;
                duration.value = 0;
                modalVisible.value = true;
                params.noticeType = 'warning';
                params.content = '无需提交行驶证';
                params.btnVisible = false;
                Object.assign(modalData, params);
              }
            } else if (state.selectedRows[0].usage === '经销商用车') {
              // Van车型处理逻辑
              if (state.selectedRows[0].make === 'VAN') {
                if (butType === 'license') {
                  if (state.selectedRows[0].invoicePath !== '') {
                    if (state.selectedRows[0].licensePath !== '') {
                      if (state.selectedRows[0].approlStatus === '0001') {
                        maskClosable.value = false;
                        duration.value = 0;
                        modalVisible.value = true;
                        params.noticeType = 'warning';
                        params.content = '已过期无法提交，请通过延期申报模块提交零售文件';
                        params.btnVisible = false;
                        Object.assign(modalData, params);
                      } else if (state.selectedRows[0].approlStatus === '1111') {
                        maskClosable.value = false;
                        duration.value = 0;
                        modalVisible.value = true;
                        params.noticeType = 'warning';
                        if (state.selectedRows[0].checkStatus === '4444') {
                          params.content = '已过期且退车，无法提交';
                        } else {
                          params.content = '已过期无法提交，请通过延期申报模块提交零售文件';
                        }
                        params.btnVisible = false;
                        Object.assign(modalData, params);
                      } else if (state.selectedRows[0].approlStatus === '2222') {
                        maskClosable.value = false;
                        duration.value = 0;
                        modalVisible.value = true;
                        params.noticeType = 'warning';
                        params.content = '该台车重复申报，无法提交';
                        params.btnVisible = false;
                        Object.assign(modalData, params);
                      }
                    } else {
                      if (state.selectedRows[0].approlStatus === '0001') {
                        maskClosable.value = false;
                        duration.value = 0;
                        modalVisible.value = true;
                        params.noticeType = 'warning';
                        params.content = '已过期无法提交，请通过延期申报模块提交零售文件';
                        params.btnVisible = false;
                        Object.assign(modalData, params);
                      } else if (state.selectedRows[0].approlStatus === '1111') {
                        maskClosable.value = false;
                        duration.value = 0;
                        modalVisible.value = true;
                        params.noticeType = 'warning';
                        if (state.selectedRows[0].checkStatus === '4444') {
                          params.content = '已过期且退车，无法提交';
                        } else {
                          params.content = '已过期无法提交，请通过延期申报模块提交零售文件';
                        }
                        params.btnVisible = false;
                        Object.assign(modalData, params);
                      } else if (state.selectedRows[0].approlStatus === '2222') {
                        maskClosable.value = false;
                        duration.value = 0;
                        modalVisible.value = true;
                        params.noticeType = 'warning';
                        params.content = '该台车重复申报，无法提交';
                        params.btnVisible = false;
                        Object.assign(modalData, params);
                      } else {
                        await isPath(state.selectedRows[0].invoicePath, 'invoice');
                        isSubVisible.value = true;
                        isInvoice.value = false;
                        isLicense.value = true;
                        isRelationProof.value = true;
                        Object.assign(selectInfo, state.selectedRows[0]);
                      }
                    }
                  } else {
                    maskClosable.value = false;
                    duration.value = 0;
                    modalVisible.value = true;
                    params.noticeType = 'warning';
                    params.content = '需先上传发票再上传行驶证';
                    params.btnVisible = false;
                    Object.assign(modalData, params);
                  }
                } else {
                  if (state.selectedRows[0].approlStatus === '0000') {
                    if (state.selectedRows[0].invoicePath) {
                      maskClosable.value = false;
                      duration.value = 0;
                      modalVisible.value = true;
                      params.noticeType = 'warning';
                      params.content = '请不要重复提交';
                      params.btnVisible = false;
                      Object.assign(modalData, params);
                    } else {
                      await isPath(state.selectedRows[0].invoicePath, 'invoice');
                      await isPath(state.selectedRows[0].licensePath, 'license');
                      isSubVisible.value = true;
                      isInvoice.value = true;
                      isLicense.value = true;
                      isRelationProof.value = true;
                      Object.assign(selectInfo, state.selectedRows[0]);
                    }
                  } else if (state.selectedRows[0].approlStatus === '0001') {
                    maskClosable.value = false;
                    duration.value = 0;
                    modalVisible.value = true;
                    params.noticeType = 'warning';
                    params.content = '已过期无法提交，请通过延期申报模块提交零售文件';
                    params.btnVisible = false;
                    Object.assign(modalData, params);
                  } else if (state.selectedRows[0].approlStatus === '1111') {
                    maskClosable.value = false;
                    duration.value = 0;
                    modalVisible.value = true;
                    params.noticeType = 'warning';
                    if (state.selectedRows[0].checkStatus === '4444') {
                      params.content = '已过期且退车，无法提交';
                    } else {
                      params.content = '已过期无法提交，请通过延期申报模块提交零售文件';
                    }
                    params.btnVisible = false;
                    Object.assign(modalData, params);
                  } else if (state.selectedRows[0].approlStatus === '2222') {
                    maskClosable.value = false;
                    duration.value = 0;
                    modalVisible.value = true;
                    params.noticeType = 'warning';
                    params.content = '该台车重复申报，无法提交';
                    params.btnVisible = false;
                    Object.assign(modalData, params);
                  }
                }
              } else {
                if (state.selectedRows[0].approlStatus === '0000') {
                  await isPath(state.selectedRows[0].invoicePath, 'invoice');
                  await isPath(state.selectedRows[0].licensePath, 'license');
                  isSubVisible.value = true;
                  isInvoice.value = true;
                  isLicense.value = true;
                  isRelationProof.value = true;
                  Object.assign(selectInfo, state.selectedRows[0]);
                } else if (state.selectedRows[0].approlStatus === '0001') {
                  maskClosable.value = false;
                  duration.value = 0;
                  modalVisible.value = true;
                  params.noticeType = 'warning';
                  params.content = '已过期无法提交，请通过延期申报模块提交零售文件';
                  params.btnVisible = false;
                  Object.assign(modalData, params);
                } else if (state.selectedRows[0].approlStatus === '1111') {
                  maskClosable.value = false;
                  duration.value = 0;
                  modalVisible.value = true;
                  params.noticeType = 'warning';
                  if (state.selectedRows[0].checkStatus === '4444') {
                    params.content = '已过期且退车，无法提交';
                  } else {
                    params.content = '已过期无法提交，请通过延期申报模块提交零售文件';
                  }
                  params.btnVisible = false;
                  Object.assign(modalData, params);
                } else if (state.selectedRows[0].approlStatus === '2222') {
                  maskClosable.value = false;
                  duration.value = 0;
                  modalVisible.value = true;
                  params.noticeType = 'warning';
                  params.content = '该台车重复申报，无法提交';
                  params.btnVisible = false;
                  Object.assign(modalData, params);
                }
              }
            }
          }
        }
      } else {
        isBatchVisible.value = true;
        if (butType === 'invoice') {
          batchActiveKey.value = '1';
        } else {
          batchActiveKey.value = '2';
        }
      }
    };
    // 取消上传方法
    const cancelUpload = async (invoiceLicense: string, batchNo: string) => {
      try {
        const res = await cancelUploadFile(batchNo);
        if (res.code !== '0') {
          maskClosable.value = false;
          duration.value = 0;
          modalVisible.value = true;
          params.noticeType = 'error';
          params.content = res.message;
          params.btnVisible = false;
          Object.assign(modalData, params);
          if (invoiceLicense == 'invoice') {
            sessionStorage.setItem('invoiceSuccess', '');
          } else {
            sessionStorage.setItem('licenseSuccess', '');
          }
        } else {
          if (invoiceLicense == 'invoice') {
            sessionStorage.setItem('invoiceSuccess', 'success');
            isBatchVisible.value = false;
          } else {
            sessionStorage.setItem('licenseSuccess', 'success');
            isBatchVisible.value = false;
          }
        }
      } catch (e) {
        maskClosable.value = false;
        duration.value = 0;
        modalVisible.value = true;
        params.noticeType = 'error';
        params.content = '取消上传失败！';
        params.btnVisible = false;
        Object.assign(modalData, params);
        if (invoiceLicense == 'invoice') {
          sessionStorage.setItem('invoiceSuccess', '');
        } else {
          sessionStorage.setItem('licenseSuccess', '');
        }
      }
    };
    // 关闭信息弹窗
    const closeModal = (param: { isVisible: boolean; continue: boolean }) => {
      params.withdrawBtnVisible = false;
      modalData.withdrawBtnVisible = false;
      maskClosable.value = false;
      duration.value = 0;
      modalVisible.value = param.isVisible;
      // 若continue为true 则调用取消上传方法
      if (param.continue) {
        const stopInvoice = sessionStorage.getItem('stopInvoice');
        if (stopInvoice) {
          sessionStorage.setItem('invoiceData', '');
          cancelUpload('invoice', stopInvoice);
        } else {
          sessionStorage.setItem('invoiceSuccess', '');
        }
        const stopLicense = sessionStorage.getItem('stopLicense');
        if (stopLicense) {
          sessionStorage.setItem('licenseData', '');
          cancelUpload('license', stopLicense);
        } else {
          sessionStorage.setItem('licenseSuccess', '');
        }
        const stopRelationProof = sessionStorage.getItem('stopRelationProof');
        if (stopRelationProof) {
          sessionStorage.setItem('relationProofData', '');
          cancelUpload('license', stopRelationProof);
        } else {
          sessionStorage.setItem('relationProofSuccess', '');
        }
      }
    };
    const closeWithdrawInfo = (visible: boolean) => {
      withdrawModelVisible.value = visible;
    };
    // 上传失败 关闭信息调用子组件方法刷新页面样式
    const isResetModal = (param: { continue: boolean }) => {
      if (isBatchVisible.value) {
        batchRef.value.isResetModal({ continue: param.continue });
      }
    };
    // 图片有问题 再次上传清空之前记录
    const isRestCount = (param: { continue: boolean }) => {
      if (isBatchVisible.value) {
        batchRef.value.isResetCount({ continue: param.continue });
      }
    };
    getOverview();
    // 关闭发票提交和行驶证提交弹窗
    const closeSub = (param: { isSubVisible: boolean; isSuccess: boolean }) => {
      isSubVisible.value = param.isSubVisible;
      sessionStorage.setItem('typePath', '');
      if (param.isSuccess) {
        // 提交成功刷新数据
        getOverview();
        getTableData();
        handleClickRow();
      }
    };

    // 关闭批量上传弹窗
    const closeBatch = (param: { isBatchVisible: boolean }) => {
      isBatchVisible.value = param.isBatchVisible;
      getOverview();
      getTableData();
      handleClickRow();
    };
    const getModalInfo = (
      param: {
        modalVisible: boolean;
        modalData: object;
      },
      maskClosableOption = false,
      durationSeconds = 0
    ) => {
      duration.value = 0;
      maskClosable.value = maskClosableOption;
      duration.value = durationSeconds;
      modalVisible.value = param.modalVisible;
      Object.assign(modalData, param.modalData);
    };
    // 设置批量查询modal 的 textNoArray
    const setBatchQueryTextNoArray = () => {
      if (batchQuery.batchQueryTitle === '车架号批量查询') {
        textNoArray.value =
          cleanEmptyEleArray(queryParams.vinNos)[0] === '' ? [] : cleanEmptyEleArray(queryParams.vinNos);
      } else {
        textNoArray.value =
          cleanEmptyEleArray(queryParams.commissionNos)[0] === '' ? [] : cleanEmptyEleArray(queryParams.commissionNos);
      }
    };
    // 设置批量查询modal 的 占位div
    const setBatchPlaceholder = () => {
      if (textNoArray.value.length) {
        batchPlaceholder.value = [];
      } else {
        batchPlaceholder.value = ['', '', '', '', '', ''];
      }
    };
    // 批量查询按钮事件
    const batchQueryEvent = (arg: string) => {
      if (arg === 'vinNo') {
        batchQuery.batchQueryModal = true;
        batchQuery.batchQueryTitle = '车架号批量查询';
        batchQuery.batchQueryListTitle = '车架号列表';
      } else {
        batchQuery.batchQueryModal = true;
        batchQuery.batchQueryTitle = '生产编号批量查询';
        batchQuery.batchQueryListTitle = '生产编号列表';
      }
      setBatchQueryTextNoArray();
      setBatchPlaceholder();
      const dom = document.getElementsByClassName('list-content')[0];
      nextTick(() => {
        dom && dom.scrollTo(0, textNoArray.value.length * 36);
        input.value.focus();
      });
    };
    // 批量文本内容
    const textChangEvent = () => {
      if (textContentNo.value.length < 5) {
        message.error('请从Excel中粘贴到文本框');
        textContentNo.value = '';
        return;
      }
      textNoArray.value = textNoArray.value.concat(
        textContentNo.value
          .replace(/\r|\n|\s/g, ',')
          .split(',')
          .filter((item) => item)
      );
      const dom = document.getElementsByClassName('list-content')[0];
      textContentNo.value = '';
      if (batchQuery.batchQueryTitle === '车架号批量查询') {
        queryParams.vinNos = [];
        queryParams.vinNos = queryParams.vinNos.concat(textNoArray.value);
      } else {
        queryParams.commissionNos = [];
        queryParams.commissionNos = queryParams.commissionNos.concat(textNoArray.value);
      }
      setBatchPlaceholder();
      nextTick(() => {
        dom.scrollTo(0, dom.scrollHeight + 40);
        input.value.focus();
      });
    };
    // 获取信息
    const getEditDet = (record: any) => {
      const params = {
        id: record.id
      };
      getBasicDetail({ params }).then((res): void => {
        if (res.price) {
          res.price = res.price.replace(/,/g, '');
          res.price = parseFloat(res.price).toFixed(2);
          res.price = res.price.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');
        }
        Object.assign(editInfoData, res);
        editInfoVisible.value = true;
      });
    };
    const editEvent = (record: any) => {
      const vinNo = record.vinNo;
      getvinMdfCheckFlag({
        vinNo: record.vinNo,
        dealerCode
      }).then((res: any) => {
        if (!res) {
          maskClosable.value = false;
          duration.value = 0;
          modalVisible.value = true;
          params.noticeType = 'warning';
          params.content = '修改截止时间已超期，不能再修改';
          params.btnVisible = false;
          Object.assign(modalData, params);
        } else {
          getEditDet(record);
        }
      });
    };
    // 关闭编辑信息弹窗
    const closeEditInfo = (param: { isVisible: boolean }) => {
      editInfoVisible.value = param.isVisible;
      getOverview();
      getTableData();
      handleClickRow();
    };
    // 关闭批量查询Modal
    const closeBatchQueryModal = () => {
      queryParams.vinNo = queryParams.vinNos[0];
      queryParams.commissionNo = queryParams.commissionNos[0];
    };
    // 清除批量查询
    const clearBatchQuery = () => {
      if (batchQuery.batchQueryTitle === '车架号批量查询') {
        queryParams.vinNos = [''];
      } else {
        queryParams.commissionNos = [''];
      }
      setBatchQueryTextNoArray();
      setBatchPlaceholder();
      input.value.focus();
    };
    const recallEvent = (record: any) => {
      postCallback(record.id).then((res: any) => {
        if (res.code === '0') {
          if (record.usage === '经销商用车') {
            if (record.relationProofPath !== '') {
              isProofContent.value = '您将撤回所上传的发票，行驶证以及关系证明文件，是否撤回？';
              isNum.value = 0;
              withdrawInfoModalRvApplyId = record.id;
              withdrawInfoModalVinNo.value = record.vinNo;
              params.retailDate = record.retailDate;
              params.brand = record.brand;
              withdrawInfoData.value = res.data;
              isProofVisible.value = true;
            } else {
              isProofContent.value = '您将撤回所上传的发票和行驶证文件，是否撤回？';
              isNum.value = 0;
              withdrawInfoModalRvApplyId = record.id;
              withdrawInfoModalVinNo.value = record.vinNo;
              params.retailDate = record.retailDate;
              params.brand = record.brand;
              withdrawInfoData.value = res.data;
              isProofVisible.value = true;
            }
          } else {
            withdrawInfoModalRvApplyId = record.id;
            withdrawInfoModalVinNo.value = record.vinNo;
            params.retailDate = record.retailDate;
            params.brand = record.brand;
            visibleWithdrawInfoModal.value = true;
            withdrawInfoData.value = res.data;
          }
        } else if (res.code === '50068') {
          //不符合延期撤回逻辑
          maskClosable.value = false;
          duration.value = 0;
          modalVisible.value = true;
          params.noticeType = 'error';
          params.content = res.message;
          params.btnVisible = false;
          Object.assign(modalData, params);
        } else if (res.code === '20001') {
          //符合延期撤回逻辑
          if (record.usage === '经销商用车') {
            if (record.relationProofPath !== '') {
              isProofContent.value = '您将撤回所上传的发票，行驶证以及关系证明文件，是否撤回？';
              isNum.value = 1;
              maskClosable.value = false;
              duration.value = 0;
              params.noticeType = 'warning';
              params.btnVisible = false;
              params.withdrawBtnVisible = true;
              params.vinNo = record.vinNo;
              params.recordId = record.id;
              params.cesarSyncStatus = record.cesarSyncStatus;
              params.retailDate = record.retailDate;
              params.brand = record.brand;
              params.content = res.message;
              Object.assign(modalData, params);
              isProofVisible.value = true;
            } else {
              isProofContent.value = '您将撤回所上传的发票和行驶证文件，是否撤回？';
              isNum.value = 1;
              params.noticeType = 'warning';
              params.btnVisible = false;
              params.withdrawBtnVisible = true;
              params.vinNo = record.vinNo;
              params.recordId = record.id;
              params.cesarSyncStatus = record.cesarSyncStatus;
              params.retailDate = record.retailDate;
              params.brand = record.brand;
              params.content = res.message;
              Object.assign(modalData, params);
              isProofVisible.value = true;
            }
          } else {
            maskClosable.value = false;
            duration.value = 0;
            modalVisible.value = true;
            params.noticeType = 'warning';
            params.btnVisible = false;
            params.withdrawBtnVisible = true;
            params.vinNo = record.vinNo;
            params.recordId = record.id;
            params.cesarSyncStatus = record.cesarSyncStatus;
            params.retailDate = record.retailDate;
            params.brand = record.brand;
            params.content = res.message;
            Object.assign(modalData, params);
          }
        } else {
          maskClosable.value = false;
          duration.value = 0;
          modalVisible.value = true;
          params.noticeType = 'error';
          params.content = res.message;
          params.btnVisible = false;
          params.withdrawBtnVisible = false;
          Object.assign(modalData, params);
        }
      });
    };
    const handleDelayWithdraw = (recordId: number, vinNo: string, retailDate: string) => {
      console.log('延期撤回的的车架号为：', recordId);
      withdrawModelVisible.value = true;
      /*params.noticeType = "success";
      params.content = "";
      params.btnVisible = false;*/
      params.recordId = recordId;
      params.vinNo = vinNo;
      params.retailDate = retailDate;
      // params.brand = brand;
      //Object.assign(modalData, params);
    };
    // 查看详情
    const detailEvent = (record: DealerTableData) => {
      sessionStorage.setItem('detailRecordId', String(record.id));
      sessionStorage.setItem('detailRecordEntity', record.entity);
      sessionStorage.setItem('detailRecordVinNo', record.vinNo);
      isDetailVisible.value = true;
    };
    // 关闭弹窗
    const closeDetail = (param: { isVisible: boolean }) => {
      isDetailVisible.value = param.isVisible;
    };
    // 关闭失败原因弹窗
    const closeFailReason = () => {
      showFailFlag.value = false;
    };
    //获取特殊车辆
    const getSpecialUsageArray = () => {
      getSpecialUsageItem().then((res) => {
        specialUsageArray.value = res.map((item: any) => item.specialUsageCn);
      });
    };

    getSpecialUsageArray();
    // 页面销毁，移除事件监听
    onUnmounted(() => {
      document.getElementsByClassName('ant-radio-checked')[0]?.removeEventListener('click', handleClickRow);
    });

    const handleWithdrawConfirm = async () => {
      if (!withdrawInfoModalRvApplyId) {
        return;
      }
      const { code, message } = await rvCallBackNormal(withdrawInfoModalRvApplyId);
      if (code === '0') {
        mulSearchEvent(); //刷新列表
        getOverview(); //查询
        // withdrawModelVisible.value = false;
        visibleWithdrawInfoModal.value = false;
      } else {
        message.error(message);
      }
    };

    function showModal(type: 'warning', content: string, btnVisible = false) {
      modalData.noticeType = type;
      modalData.content = content;
      modalData.btnVisible = btnVisible;
      modalVisible.value = true;
    }

    /**
     * 单条关系证明上传预校验
     */
    async function submitRelationProofCheck(record: Record<string, unknown>) {
      // if (!record.invoicePath && !record.licensePath && !record.relationProofPath) {
      //   showModal('warning', '该台车重复申报，无法提交')
      //   return false
      // }
      if (record.usage !== '经销商用车') {
        showModal('warning', '无需提交关系证明');
        return false;
      }
      if (record.approlStatus === RvApprovalStatus.SUBMITTED && record.relationProofPath) {
        showModal('warning', '该台车重复申报，无法提交');
        return false;
      }
      if (
        record.approlStatus === RvApprovalStatus.SUBMITTED &&
        !record.relationProofPath &&
        moment(overview.value.currRetailSubmitDate as string)
          .isBefore(moment(), 'day')
      ) {
        showModal('warning', '已过提交日期，关系证明无法提交');
        return false;
      }
      if (record.approlStatus === RvApprovalStatus.EXPIRED) {
        showModal('warning', '已过期无法提交，请通过延期申报模块提交零售文件');
        return false;
      }
      try {
        await relationProofPreCheck(record.vinNo as string, dealerCode);
      } catch (e: any) {
        showModal('warning', e.response.data.message);
        return false;
      }
      return true;
    }

    async function handleToSubmitRelationProof() {
      if (state.selectedRows && state.selectedRows.length > 0) {
        if (!(await submitRelationProofCheck(state.selectedRows[0]))) {
          return;
        }

        isInvoice.value = true;
        isLicense.value = true;
        isRelationProof.value = true;
        Object.assign(selectInfo, state.selectedRows[0]);
        isSubVisible.value = true;
      } else {
        isBatchVisible.value = true;
        batchActiveKey.value = '3';
      }
    }
    const closeProofModal = () => {
      isProofVisible.value = false;
    };
    const openRecallModal = () => {
      isProofVisible.value = false;
      if (isNum.value == 0) {
        visibleWithdrawInfoModal.value = true;
      }
      if (isNum.value == 1) {
        modalVisible.value = true;
      }
    };

    return {
      isOpen,
      isOpenPanle,
      showFailFlag,
      columns,
      activeKey,
      handleClick,
      handleActiveKey,
      showCollapsed,
      ...toRefs(batchQuery),
      ...toRefs(queryParams),
      ...toRefs(state),
      ...toRefs(pictureModal),
      overview,
      tableData,
      getTableData,
      overviewSerachEvent,
      mulSearchEvent,
      usageArray,
      specialUsageArray,
      entityArray,
      approvalStatusArray,
      rebateFlagArray,
      total,
      pageChange,
      sizeChange,
      queryParams,
      viewReasonEvent,
      handleExportResult,
      editEvent,
      recallEvent,
      detailEvent,
      isDetailVisible,
      closeDetail,
      closeFailReason,
      failData,
      entityData,
      resetEvent,
      licenseEvent,
      invoiceEvent,
      relationProofEvent,
      downloadImg,
      hasSelected,
      onSelectChange,
      isSubVisible,
      closeSub,
      isInvoice,
      isLicense,
      isRelationProof,
      selectInfo,
      invoiceLicenseSub,
      tableHeight,
      tableWidth,
      getOverview,
      cancelUpload,
      modalVisible,
      maskClosable,
      duration,
      withdrawModelVisible,
      withdrawCompletedVisible,
      modalData,
      params,
      getModalInfo,
      closeModal,
      isBatchVisible,
      closeBatch,
      batchActiveKey,
      batchQueryEvent,
      textContentNo,
      textChangEvent,
      textNoArray,
      batchPlaceholder,
      EnumDealerCheckStatus,
      EnumDealerApprovalStatus,
      clearBatchQuery,
      closeBatchQueryModal,
      isResetModal,
      isRestCount,
      batchRef,
      input,
      isPath,
      editInfoVisible,
      editInfoData,
      closeEditInfo,
      getEditDet,
      dateChangeEvent,
      btnCon,
      ...toRefs(selectData),
      brandArray,
      handleDelayWithdraw,
      closeWithdrawInfo,
      visibleWithdrawInfoModal,
      withdrawInfoModalVinNo,
      withdrawInfoColumns,
      withdrawInfoData,
      disabledWithdraw,
      handleWithdrawConfirm,
      handleToSubmitRelationProof,
      isProofVisible,
      isProofContent,
      isNum,
      closeProofModal,
      openRecallModal
    };
  }
});
